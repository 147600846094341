import React from 'react';
import Layout from "../components/layout"

import Pdf from '../assets/Anmeldeformular.pdf';

const Anmeldeformular = () => {

  

  return (
      <Layout>
        <div className="content">
          <a href={Pdf} target="_blank" rel='noopener noreferrer'>PDF</a>
        </div>
      </Layout>
  )
}

export default Anmeldeformular;